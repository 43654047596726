import React, { ReactNode, useEffect, useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContentText, DialogActions, Typography, FormGroup, SelectChangeEvent, MenuItem, CircularProgress, ThemeProvider } from '@mui/material';
import { account_controller } from '../../controllers/account_controller';
import { useRecoilState } from 'recoil';
import { firstTimeLoginAtom } from '../../models/atoms/account_atoms';
import { useTranslation } from '../../contexts/TranslationContext';
import { MHMDialogContent } from './DialogContent';
import { MHMTextField } from './TextField';
import { MHMTypography } from './Typography';
import { MHMDatePicker } from './DatePicker';
import { MHMLabelledCheckbox } from './Checkbox';
import { event } from 'cypress/types/jquery';
import { device_controller } from '../../controllers/device_controller';
import { MHMLabelledSelect, MHMSelect } from './Select';
import { MHMLoadingIcon } from './LoadingIcon';
import { theme } from './Theme';

type DownloadCSVModalProps = {
	open: boolean
	setOpen: (open: boolean) => void
	min_start_date: Date,
	max_end_date: Date,
	initial_start_date: Date,
	initial_end_date: Date,
	show_rainfall: boolean,
	show_water_level: boolean,
	device_id: string
}

const file_types = ['csv', 'excel', 'feather', 'html', 'json', 'latex', 'markdown', 'parquet', 'pickle', 'stata', 'xarray', 'xml'];

const DownloadCSVModal: React.FC<DownloadCSVModalProps> = (props: DownloadCSVModalProps) => {
	const { t } = useTranslation();
	const [startDate, setStartDate] = useState<Date>(props.initial_start_date);
	const [endDate, setEndDate] = useState<Date>(props.initial_end_date);
	const [showRainfall, setShowRainfall] = useState<boolean>(props.show_rainfall);
	const [showWaterLevel, setShowWaterLevel] = useState<boolean>(props.show_water_level);
	const min_start_date = props.min_start_date;
	const max_end_date = props.max_end_date;
	const [fileType, setFileType] = useState<string>('csv');
	const [valid, setValid] = useState<boolean>(true);
	const [loading, setLoading] = useState<boolean>(false);

	useEffect(() => {
		console.log(startDate, endDate, fileType);
		console.log(valid)
		setValid(validate());	
	}, [startDate, endDate, fileType]);

	useEffect(() => {
		setShowRainfall(props.show_rainfall);
		setShowWaterLevel(props.show_water_level);
		setStartDate(props.initial_start_date);
		setEndDate(props.initial_end_date);
	}, [props])

	const validate = () => {
		console.log(startDate, endDate, min_start_date, max_end_date, fileType);
		if (startDate < min_start_date || startDate > endDate) {
			console.log(1)
			return false;
		}
		if (endDate > max_end_date || endDate < startDate) {
			console.log(2)
			return false;
		}
		if (!file_types.includes(fileType)) {
			console.log(3)
			return false;
		}
		return true;
	}

	console.log('render', valid)

	return (
		<Dialog open={props.open} onClose={() => props.setOpen(false)}>
			<DialogTitle>{t("Download Data")}</DialogTitle>
			<MHMDialogContent>
				<DialogContentText>{t("Start Date")}</DialogContentText>
				<MHMDatePicker
					value={startDate}
					shouldDisableDate={(date: Date) => {
						return date.getTime() < min_start_date.getTime() || date.getTime() > endDate.getTime();
					}}
					onChange={(date: Date) => {setStartDate(date)}}
				/>
				<DialogContentText>{t("End Date")}</DialogContentText>
				<MHMDatePicker
					value={endDate}
					shouldDisableDate={(date: Date) => {
						return date.getTime() > max_end_date.getTime() || date.getTime() < startDate.getTime();
					}}
					onChange={(date: Date) => {setEndDate(date)}} />
				<FormGroup>
					<MHMLabelledCheckbox
						checkboxProps={{checked: showWaterLevel, onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
							setShowWaterLevel(event.target.checked);
						}}}
						label={<MHMTypography>{t("Include Water Level")}</MHMTypography>}
					/>
					<MHMLabelledCheckbox
						checkboxProps={{checked: showRainfall, onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
							setShowRainfall(event.target.checked);
						}}}
						label={<MHMTypography>{t("Include Rainfall")}</MHMTypography>}
					/>
				</FormGroup>
				<FormGroup sx={{alignItems: 'end', justifyContent: 'start'}}>
					<MHMLabelledSelect
						selectProps={{value: fileType, onChange: (event: SelectChangeEvent<unknown>, child: ReactNode) => {setFileType(event.target.value as string)}}}
						label={<MHMTypography sx={{marginRight: '1rem'}}>{t("File Type:")}</MHMTypography>}
					>
						{file_types.map((file_type: string) => {
							return <MenuItem value={file_type}>{file_type}</MenuItem>
						})}
					</MHMLabelledSelect>
				</FormGroup>
			</MHMDialogContent>
			<DialogActions>
				{loading ? <MHMLoadingIcon size={25}/> :
				<ThemeProvider theme={theme}>
				<Button color="primary" disabled={!valid} onClick={async () => {
					console.log('a')
					if (!validate()) {
						return;
					}	
					console.log('b')
					setLoading(true);
					await device_controller.downloadCSV(props.device_id, startDate.getTime(), endDate.getTime(), showRainfall, showWaterLevel, fileType);
					setLoading(false);
					console.log('c')
					props.setOpen(false);
				}}>{t("Download")}</Button>
				</ThemeProvider>}
			</DialogActions>
		</Dialog>
	);
};

export default DownloadCSVModal;