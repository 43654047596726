import styled from "@emotion/styled";
import DialogContent, { DialogContentProps } from "@mui/material/DialogContent/DialogContent";

const StyledDialogContent = styled(DialogContent)`
	.MuiDialogContent-root {
		display: flex;
		flex-direction: column;
	}
`;

export const MHMDialogContent: React.FC<DialogContentProps> = (props: DialogContentProps) => {
	return (
		<StyledDialogContent
			{...props}
		/>
	);
}