import styled from "@emotion/styled";
import TextField, { TextFieldProps } from "@mui/material/TextField/TextField";

const StyledTextField = styled(TextField)`
	.MuiTextField-root {
		margin: 1rem;
	}
`;

export const MHMTextField: React.FC<TextFieldProps> = (props: TextFieldProps) => {
	return (
		<StyledTextField
			{...props}
		/>
	);
}