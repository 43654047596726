import { StyleSheet } from 'aphrodite';
import { button_background_colour, button_font_size, button_font_weight, button_text_colour, primary_background_colour } from './style_variables';

export const map_styles = StyleSheet.create({
	map_component: {
		height: '100%',
		width: '100%',
		position: 'relative',
		flex: 1,
		padding: '1rem',
		borderRadius: 3,
		overflow: 'hidden',
	},

	map_container: {
		height: '100%',
		borderRadius: 3,
	}
});
