import styled from "@emotion/styled";
import Typography, { TypographyProps } from "@mui/material/Typography/Typography";
import { ReactNode } from "react";

const StyledTypography = styled(Typography)`
	.MuiTypography-root {
		color: black;
		font-weight: 600;
		font-family: 'Ubuntu', sans-serif;
	}
`;

export const MHMTypography: React.FC<TypographyProps & {children?: ReactNode}> = (props: TypographyProps) => {
	return (
		<StyledTypography {...props}>
			{props.children}
		</StyledTypography>
	);
}