import styled from "@emotion/styled";
import { isValidNumber } from "libphonenumber-js";
import { parseIncompletePhoneNumber, parsePhoneNumberWithError } from "libphonenumber-js/max";
import { classes, MuiTelInput } from "mui-tel-input";
import React, { memo, useState } from "react";
import { useTranslation } from "../../../contexts/TranslationContext";

const StyledPhoneNumberInput = styled(MuiTelInput)`
		.MuiInputBase-root {
			border-radius: 5px;
			padding: 0.0rem 0.0rem;
			min-width: 20rem;
			margin-right: 0.2rem;
			margin-bottom: 0.5rem;
		}
		.${classes.flagContainer} {
			padding: 0.0rem 0.5rem;
		}
		.${classes.flagButton} {
			padding: 0.0rem 0.5rem;
		}
		.MuiInputBase-input {
			padding: 0.0rem 0.5rem;
			font-family: 'Ubuntu', sans-serif;
		}
	`

export const PhoneNumberInput = memo((props: { phoneNumber: string; setPhoneValidationError: (arg0: string) => void; onValid: (arg0: string) => void; name: string | undefined}) => {
	const [phoneNumber, setPhoneNumber] = useState(props.phoneNumber);
	const { t } = useTranslation();
	console.log('rerender phone number')

	return <StyledPhoneNumberInput name={props.name} key={'phoneNumberInput'} value={phoneNumber} onChange={(value) => {
			props.setPhoneValidationError('');
			const phone_number_string = parseIncompletePhoneNumber(value);
			setPhoneNumber(value.toString())
			console.log(phone_number_string)
			try {
				const phone_number = parsePhoneNumberWithError(phone_number_string);
				if (!isValidNumber(phone_number_string, phone_number.country)) {
					props.setPhoneValidationError(t("Please provide a valid phone number!"));
					return;
				}
				props.onValid(phone_number_string)
				//if (changeUser == null) { return; }
				//props.setChangeUser({...changeUser, phone_number: phone_number_string, change_email_phone: true});
			} catch (e) {
				//console.log(e)
				props.setPhoneValidationError(t("Please provide a valid phone number!"));
			}
		}}/>
})

const ModalStyledPhoneNumberInput = styled(MuiTelInput)`
		.MuiInputBase-root {
			border-radius: 5px;
			padding: 0.1875rem 0.75rem;
		}
		.${classes.flagContainer} {
			padding: 0.0rem 0.5rem;
		}
		.${classes.flagButton} {
			padding: 0.0rem 0.5rem;
		}
		.MuiInputBase-input {
			padding: 0.0rem 0.5rem;
			font-family: 'Ubuntu', sans-serif;
		}
	`

export const ModalPhoneNumberInput = memo((props: { phoneNumber: string; setPhoneValidationError: (arg0: string) => void; onValid: (arg0: string) => void; name: string | undefined}) => {
	const [phoneNumber, setPhoneNumber] = useState(props.phoneNumber);
	const { t } = useTranslation();
	console.log('rerender phone number')

	return <ModalStyledPhoneNumberInput sx={{width: '100%'}} name={props.name} key={'phoneNumberInput'} value={phoneNumber} onChange={(value) => {
			props.setPhoneValidationError('');
			const phone_number_string = parseIncompletePhoneNumber(value);
			setPhoneNumber(value.toString())
			console.log(phone_number_string)
			try {
				const phone_number = parsePhoneNumberWithError(phone_number_string);
				if (!isValidNumber(phone_number_string, phone_number.country)) {
					props.setPhoneValidationError(t("Please provide a valid phone number!"));
					return;
				}
				props.onValid(phone_number_string)
				//if (changeUser == null) { return; }
				//props.setChangeUser({...changeUser, phone_number: phone_number_string, change_email_phone: true});
			} catch (e) {
				//console.log(e)
				props.setPhoneValidationError(t("Please provide a valid phone number!"));
			}
		}}/>
})