/* eslint-disable no-useless-escape */
import '../../App.css';
import "bootstrap-icons/font/bootstrap-icons.css";
import '../styles/shared_styles.css';
import {
  useRecoilValue,
} from 'recoil';
import { panelSizesAtom } from "../../models/atoms/ui_atoms";
import MapContainer from '../../utils/Map';
import React from 'react';
import { colors } from '../styles/style_variables';
import { FleetMetrics } from './FleetMetrics';
import { css } from 'aphrodite';
import { map_styles } from '../styles/map_styles';




export const MapComponent = (props: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
	return <div data-testid="Map Component" className={css(map_styles.map_component)}>
		<MapContainer/>
		<FleetMetrics/>
	</div>;
}


