import { StyleSheet } from 'aphrodite';
import { button_background_colour, button_font_size, button_text_colour, button_font_weight } from './style_variables';

export const table_styles = StyleSheet.create({
	table_cell: {
		backgroundColor: 'white',
	},
	table_cell_selected: {
		backgroundColor: '#f5f2f3',
	},
})

export const button_styles = StyleSheet.create({
    main_button: {
        borderRadius: 5,
        border: 'none',
        backgroundColor: button_background_colour,
        fontSize: button_font_size,
        fontWeight: button_font_weight,
        color: button_text_colour,
    },
    main_button_disabled: {
        borderRadius: 5,
        border: 'none',
        backgroundColor: 'lightgrey',
        fontSize: button_font_size,
        fontWeight: button_font_weight,
        color: button_text_colour,
        minWidth: '5rem'
    },
    delete_button: {
        padding: '0.5rem',
        backgroundColor: '#6c757d',
        borderColor: '#6c757d',
        float: 'right',
    },
    cross_button: {
        float: 'right',
        borderRadius: 6,
        margin: '0 1rem',
        width: '2.5rem',
        backgroundColor: button_background_colour,
        borderColor: button_background_colour,
    },
    add_label_button: {
        backgroundColor: button_background_colour,
        border: 'None',
        fontWeight: 500
    },
    remove_label_button: {
        width: '1.5rem',
    }
});

export const label_styles = StyleSheet.create({
    labels_container: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'center',
    },
    label: {
        margin: '0.5rem 0.5rem 0.5rem 0',
        padding: '0.2rem 0.4rem',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        border: `2px solid #000099`,
        borderRadius: 5,
        color: 'black',
        fontSize: '1rem',
        fontWeight: 500,
        textAlign: 'center'
    },
    label_red: {
        margin: '0.5rem 0.5rem 0.5rem 0',
        padding: '0.2rem 0.4rem',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        border: `2px solid red`,
        borderRadius: 5,
        color: 'black',
        fontSize: '1rem',
        fontWeight: 500,
        textAlign: 'center'
    }
})