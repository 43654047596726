import styled from "@emotion/styled";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs';

const StyledDatePicker = styled(DateTimePicker)`
	.Mui-error {
		border-color: #e0e0e0;
	}
`;

type DatePickerProps = {
	value: Date
	onChange: (date: Date) => void
	shouldDisableDate?: (date: Date) => boolean
	error?: boolean
}

export const MHMDatePicker: React.FC<DatePickerProps> = (props: DatePickerProps) => {
	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<StyledDatePicker
				value={dayjs(props.value)}
				onChange={(date: Dayjs | null) => {
					if (!date) {
						return;
					}
					props.onChange(date.toDate());
				}}
				shouldDisableDate={(date: Dayjs) => {
					return props.shouldDisableDate ? props.shouldDisableDate(date.toDate()) : false;
				}}
				shouldDisableTime={(date: Dayjs) => {
					return props.shouldDisableDate ? props.shouldDisableDate(date.toDate()) : false;
				}}
				slotProps={{ textField: { error: props.error } }}
			/>
		</LocalizationProvider>
	);
}