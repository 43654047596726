import { css } from "aphrodite";
import { deviceInfoPanelStyles } from "../../styles/device_metrics_page_styles";
import { useTranslation } from "../../../contexts/TranslationContext";
import { ComposedChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';

const formatDate = (date: number, language: string, date_format: Intl.DateTimeFormatOptions) => {
	const numericDate = new Date(date * 1000);
	var dateLanguage = 'en-GB';
	if (language === 'de') {
		dateLanguage = 'de-DE';
	}
	return numericDate.toLocaleDateString(dateLanguage, date_format);
};

const computeTicks = (data: any) => {
	if (!data || !data.times || data.times.length === 0) {
		return [];
	}
	return [data.times[0], data.times[Math.floor(data.times.length / 2)], data.times[data.times.length - 1]];
}

export const DeviceGraph = (props : any) => {
	const { t, language } = useTranslation(); 
	//const data = props.data.map((x: any) => { return {rainfall_level_mm: 0, water_level_mm: 0, ...x}})
	const data = [];
	if (props.data) {
		for (let i = 0; i < props.data.times.length; i++) {
			data.push({
				time: props.data.times[i],
				water_level: props.data.water_levels[i],
				rainfall_level: props.data.rainfall_levels[i],
			});
		}
	}
	console.log(data)
	return (<div>
		{props.title && <p className={css(deviceInfoPanelStyles.subsection_text)}> <span style={{ fontWeight: 500 }}>{t(props.title)}</span></p>}
		<ResponsiveContainer width={'100%'} height={300} style={{paddingRight: props.show_rainfall && props.show_water_level ? 0 : 70}}>
			<ComposedChart height={300} className={css(deviceInfoPanelStyles.chart)} data={data}>
				<CartesianGrid stroke="#ccc" />
				<Tooltip isAnimationActive={false} labelFormatter={(date) => formatDate(date, language, { day: 'numeric', month: 'short', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' }) }/>
				<XAxis xAxisId="time" dataKey="time" tickFormatter={(date) => formatDate(date, language, { day: 'numeric', month: 'short', year: 'numeric' })} type="number" scale="time" domain={['auto', 'auto']} ticks={computeTicks(props.data)}/>
				{props.show_water_level && <>
					<Line isAnimationActive={false} type="monotone" yAxisId="water" xAxisId="time" dataKey="water_level" stroke="rgb(0, 0, 153)" strokeWidth="3px" dot={false}/>
					<YAxis yAxisId="water" dataKey="water_level" width={70} stroke="rgb(0, 0, 153)" domain={[0, props.selectedDevice.backend_max_height_mm]} scale='linear'></YAxis>
				</>}
				{props.show_rainfall && <>
					<Line isAnimationActive={false} type="monotone" yAxisId="rainfall" xAxisId="time" dataKey="rainfall_level" stroke="rgb(0, 153, 0)" strokeWidth="3px" dot={false}/>
					<YAxis yAxisId="rainfall" dataKey="rainfall_level" stroke="rgb(0, 153, 0)" orientation={props.show_water_level ? "right" : "left"} width={70} domain={[0, "dataMax + 1"]} scale='linear'></YAxis>
				</>}
			</ComposedChart>
		</ResponsiveContainer>
	</div>);
}