import styled from "@emotion/styled";

//  @ts-ignore
import CheckboxFull from "../../assets/Checkbox-Full.svg";
//  @ts-ignore
import CheckboxEmpty from "../../assets/Checkbox-Empty.svg";
import Checkbox, { CheckboxProps } from "@mui/material/Checkbox/Checkbox";
import { FormControlLabel, FormControlLabelProps } from "@mui/material";
import { useTranslation } from "../../contexts/TranslationContext";
import { ReactNode } from "react";

const StyledCheckbox = styled(Checkbox)`

`;

export const MHMCheckbox: React.FC<CheckboxProps> = (props: CheckboxProps) => {
	return (
		<StyledCheckbox
			checkedIcon={<img src={CheckboxFull} style={{width: 50}} alt="checked" />}
			icon={<img src={CheckboxEmpty} style={{width: 50, color: "#000099"}} alt="unchecked" />}
			sx={{color: '#000099'}}
			{...props}
		/>
	);
}

export const MHMLabelledCheckbox: React.FC<{ checkboxProps: CheckboxProps, label: ReactNode }> = (props: { checkboxProps: CheckboxProps, label: ReactNode }) => {
	return (
		<FormControlLabel label={props.label} control={<MHMCheckbox {...props.checkboxProps} />} />
	);
}
