import { StyleSheet } from 'aphrodite';
import { colors, sizes } from './style_variables';

export const appStyles = StyleSheet.create({
    app_style: {
      textAlign: "left",
      backgroundColor: colors.light_grey,
    },
    nav_button_styles: {
      display: 'inline-block',
      float: 'left',
      borderColor: '#aad8e2',
      backgroundColor: colors.blue,
      paddingTop: 0,
      paddingBottom: 0,
      marginLeft: 10,
      height: 40,
      marginTop: 5
    },
    nav_header_styles: {
      marginRight: 20,
      marginLeft: 10
    },
    nav_bar_styles: {
      justifyContent: 'flex-end',
      display: 'flex',
      height: sizes.header,
      background: colors.blue,
      margin: 0,
      paddingTop: 5,
      paddingRight: 15,
    },
    footer_styles: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: sizes.header,
      background: colors.blue,
      margin: 0,
      paddingTop: 5,
      paddingRight: 20,
      bottom: '0 !important',
      position: 'fixed',
      width: '100%',
	  zIndex: 10,
    },
    logout_button: {
      display: 'inline-block',
      float: 'right',
      borderColor: '#aad8e2',
      backgroundColor: colors.blue,
      paddingTop: 0,
      paddingBottom: 0,
      marginLeft: 10,
      height: 40,
      marginTop: 5
    },
    icons: {
      fontSize: 25
    },
    current_page: {
      backgroundColor: 'white !important',
      color: 'black'
    }
  });