//  @ts-ignore
import mhm_logo from "../../../assets/logo-mhm.png";
//  @ts-ignore
import cross_icon from "../../../assets/cross-icon.svg";
//  @ts-ignore
import CheckboxFull from "../../../assets/Checkbox-Full.svg";
//  @ts-ignore
import CheckboxEmpty from "../../../assets/Checkbox-Empty.svg";
/* eslint-disable no-useless-escape */
import '../../styles/shared_styles.css';
import '../../../App.css';
import { css } from 'aphrodite';
import "bootstrap-icons/font/bootstrap-icons.css";
import {
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';
import { companyWeathermodeSettingsAtom, companyROCSettingsAtom, companyLowPowerSettingsAtom, labelsAtom } from "../../../models/atoms/company_atoms";
import { dateRangeAtom, selectedDeviceDataAtom, selectedDeviceStateAtom } from "../../../models/atoms/device_atoms";
import { alphanumeric_comparator, get_signal_strength_value, unix_timestamp_to_date_time } from "../../../utils/helper_functions";
import { button_styles, label_styles } from "../../styles/reusable_styles";
import { deviceInfoPanelStyles } from "../../styles/device_metrics_page_styles";
import { useState, memo, useEffect, useRef } from "react";
import { company_controller } from "../../../controllers/company_controller";
import { device_controller } from "../../../controllers/device_controller";
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts';
import React from 'react';
import { chartDataInterface, rainDataInterface } from "../../../models/interfaces/device_interfaces";
import { useTranslation } from '../../../contexts/TranslationContext'; // Import the useTranslation hook
import { accountCompanyInfoAtom, selectedAccountStateAtom } from "../../../models/atoms/account_atoms";
import { DeviceGraph } from "./DeviceGraph";
import { Checkbox } from "@mui/material";
import DownloadCSVModal from "../DownloadCSVModal";
import dayjs, { Dayjs } from "dayjs";
import { MHMDatePicker } from "../DatePicker";

const image_url = process.env.REACT_APP_DEVICE_API_URL + '/image/';

export const DeviceInfoPanel = memo((_: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
  const { t, language } = useTranslation(); // Use the translation hook
  const selectedDevice = useRecoilValue(selectedDeviceStateAtom);
  const company = useRecoilValue(labelsAtom);
  const setSelectedDevice = useSetRecoilState(selectedDeviceStateAtom);
  const selectedDeviceData = useRecoilValue(selectedDeviceDataAtom);
  const account = useRecoilValue(selectedDeviceStateAtom);
  const [commentChanges, setCommentChanged] = useState(false);
  const [warningLevelChanged, setWarningLevelChanged] = useState(false);
  const [warningLevel, setWarningLevel] = useState(selectedDevice?.warning_level_percentage);
  const [commentText, setCommentText] = useState('');
  const [deviceID, setDeviceID] = useState('');
  const [warningThresholdError, setWarningThresholdError] = useState('');
  const text_area = useRef(null);
  const [labelsDropdown, setLabelsDropdown] = useState(false);
  const alertLabels = ["Low battery", "No messages", "High ROC", "High Level", "blockage", "silt", "flood", "infiltration"];
  const specialLabels = ["StormWater", "Sewage", "Combined", "Unknown"];
  const companyLowPowerSettings = useRecoilValue(companyLowPowerSettingsAtom);
  const companyWeathermodeSettings = useRecoilValue(companyWeathermodeSettingsAtom);
  const companyROCSettings = useRecoilValue(companyROCSettingsAtom);
  const [periodType, setPeriodType] = useState('day');
  const dateRange = useRecoilValue(dateRangeAtom);
  const [periodStartDate, setPeriodStartDate] = useState(dayjs().startOf('day'));
  const [periodEndDate, setPeriodEndDate] = useState(dayjs().endOf('day'));
  const accountState = useRecoilValue(accountCompanyInfoAtom);
  const [show_rainfall, setShowRainfall] = useState(false);
  const [show_water_level, setShowWaterLevel] = useState(true);
  const [show_download, setShowDownload] = useState(false);

  const changePeriodType = (periodType: 'day' | 'week' | 'month' | 'year' | 'all') => {
	var newPeriodStartDate: Dayjs = dateRange.end;
	var newPeriodEndDate: Dayjs = dateRange.end;
	if (periodType === 'all') {
		newPeriodStartDate = dateRange.start;
		newPeriodEndDate = dateRange.end;
	} else {
    	newPeriodStartDate = newPeriodStartDate.startOf(periodType);
		newPeriodEndDate = newPeriodStartDate.endOf(periodType);
	}
    setPeriodStartDate(newPeriodStartDate);
	setPeriodEndDate(newPeriodEndDate);
    setPeriodType(periodType);
	if (!selectedDevice) {
		return;
	}
	device_controller.get_device_data(selectedDevice.device_id, newPeriodStartDate.unix(), newPeriodEndDate.unix());
  };

  const increasePeriod = (periodType: 'day' | 'week' | 'month' | 'year' | 'all') => {
    var newPeriodStartDate: Dayjs = periodStartDate;
	var newPeriodEndDate: Dayjs = periodEndDate;
	if (periodType === 'all') {
		return;
	}
	console.log('newPeriodStartDate', newPeriodStartDate);
	newPeriodStartDate = newPeriodStartDate.add(1, periodType);
	newPeriodEndDate = newPeriodStartDate.endOf(periodType);
	console.log('newPeriodStartDate', newPeriodStartDate);
	if (newPeriodStartDate > dateRange.end) {
		return;
	}
    setPeriodStartDate(newPeriodStartDate);
	setPeriodEndDate(newPeriodEndDate);
	if (!selectedDevice) {
		return;
	}
	device_controller.get_device_data(selectedDevice.device_id, newPeriodStartDate.unix(), newPeriodEndDate.unix());
  };

  const decreasePeriod = (periodType: 'day' | 'week' | 'month' | 'year' | 'all') => {
    var newPeriodStartDate: Dayjs = periodStartDate;
	var newPeriodEndDate: Dayjs = periodEndDate;
	if (periodType === 'all') {
		return;
	}
	newPeriodStartDate = newPeriodStartDate.subtract(1, periodType);
	newPeriodEndDate = newPeriodStartDate.endOf(periodType);
	if (newPeriodEndDate < dateRange.start) {
		return;
	}
    setPeriodStartDate(newPeriodStartDate);
	setPeriodEndDate(newPeriodEndDate);
	if (!selectedDevice) {
		return;
	}
    device_controller.get_device_data(selectedDevice.device_id, newPeriodStartDate.unix(), newPeriodEndDate.unix());
  };

  useEffect(() => {
    company_controller.get_low_power_settings();
    company_controller.get_weathermode_settings();
    company_controller.get_roc_settings();
  }, []);

  useEffect(() => {
	changePeriodType(periodType as 'day' | 'week' | 'month' | 'year' | 'all');
  }, [dateRange]);

  if ((selectedDevice) === null) {
    return <div className={css(deviceInfoPanelStyles.logo_container)}>
      <img src={mhm_logo} alt="logo" className={css(deviceInfoPanelStyles.logo)} />
    </div>
  }

  if (!(deviceID === selectedDevice.device_id)) {
    setDeviceID(selectedDevice.device_id);
    setCommentText(selectedDevice.comments);
    setWarningLevel(selectedDevice?.warning_level_percentage);
    setCommentChanged(false);
    setWarningLevelChanged(false);
    console.log('companyLowPowerSettings');
  }
  else {
    console.log('companyLowPowerSettings', companyLowPowerSettings);
    console.log('selectedDevice.battery_percentage', selectedDevice.battery_percentage);
    console.log('selectedDevice.signal_strength', selectedDevice.signal_strength);
  }

  <textarea
    id='commentTextAreaBlockInfo'
    className={css(deviceInfoPanelStyles.comments)}
    value={commentText}
    onChange={(event) => {
      setCommentChanged(!(event.target.value === selectedDevice.comments));
      setCommentText(event.target.value);
    }} ></textarea>;


  const dateStr = unix_timestamp_to_date_time(selectedDevice.creation_date, true, true, false);

  return <article data-testid="Device Info Component" className={css(deviceInfoPanelStyles.panel_container)}>
    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
      <h1 className={css(deviceInfoPanelStyles.panel_heading)}>{t("Device ID:")} {selectedDevice.device_id} {selectedDevice.pinned ? <i className="bi-pin-fill"/> : <i className=""/>} </h1>
      <img style={{ width: '2.5rem' }} alt='device cross icon' src={cross_icon} onClick={() => setSelectedDevice(null)} />
    </div>

    <section>
      <h2 className={css(deviceInfoPanelStyles.section_heading)}>{t("Device Info")}</h2>
      <p className={css(deviceInfoPanelStyles.subsection_text)}> <span style={{ fontWeight: 500 }}>{t("Location:")} </span>
        <a href={`https://www.google.com/maps/place/${selectedDevice.latitude},${selectedDevice.longitude}`} target="_blank" rel="noreferrer" style={{ display: 'inline-block' }}>
          <p className={css(deviceInfoPanelStyles.link)}>{t("Google Maps Link")}</p>
        </a>
	  </p>
      <p className={css(deviceInfoPanelStyles.subsection_text)}><span style={{ fontWeight: 500 }}>{t("Latitude, Longitude:")}</span> {selectedDevice.latitude}, {selectedDevice.longitude}</p>
      <p className={css(deviceInfoPanelStyles.subsection_text)}><span style={{ fontWeight: 500 }}>{t("Battery Percentage:")}</span> {selectedDevice.battery_percentage}%</p>
      <p className={css(deviceInfoPanelStyles.subsection_text)}><span style={{ fontWeight: 500 }}>{t("Alerts:")}</span></p>

      <div style={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
      }}>
        {selectedDevice.labels.filter(label => alertLabels.includes(label)).sort(alphanumeric_comparator).map((label) => {
          return <div className={css(label_styles.label_red)}>
            <p style={{ margin: 0 }}>{t(label)}</p>
          </div>
        })}
      </div>
      <p className={css(deviceInfoPanelStyles.disclaimer)}>{t("High level, Low battery, blockage alerts etc. will display here")}</p>
      <p className={css(deviceInfoPanelStyles.subsection_text)}><span style={{ fontWeight: 500 }}>{t("Labels:")}</span></p>
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
      }}>
        {specialLabels.map(label => {
          if (selectedDevice.labels.includes(label)) {
            return (
              <div className={css(label_styles.label)}>
                <p style={{ margin: 0 }}>{t(label)}</p>
              </div>
            );
          }
          return null;
        })}
        {selectedDevice.labels.filter(label => !specialLabels.includes(label)).sort(alphanumeric_comparator).map(label => {
          if (label === "Low battery" || label === "No messages" || label === "In Weather Mode" || alertLabels.includes(label)) {
            return null
          }
          return <div className={css(label_styles.label)}>
            <p style={{ margin: 0 }}>{t(label)}</p>
            <img alt='label cross icon' src={cross_icon} className={css(button_styles.remove_label_button)} onClick={async () => {
              await company_controller.remove_device_label(selectedDevice.device_id, label);
              await device_controller.get_device_list(() => { device_controller.select_device(selectedDevice.device_id) });
            }} />
          </div>
        })}
      </div>
      <p className={css(deviceInfoPanelStyles.disclaimer)}>{t("Navigate to settings page to create or delete labels")}</p>
      <button className={css(button_styles.main_button)} style={{ padding: '0.5rem 1rem', margin: '0.3rem 0' }} onClick={() => setLabelsDropdown(true)}>{t("Add Label")}</button>

      <div style={{ position: 'relative' }}>
        {labelsDropdown && <div className='dropdown' style={{ top: '-3rem', left: '8rem', maxHeight: '9rem' }} onMouseLeave={() => setLabelsDropdown(false)} >
          {company.labels.length < 1 ? <p className='dropdown_item'>{t("No Labels available")}</p> :
            company.labels.slice().sort(alphanumeric_comparator).map((label) => {
              return <p className='dropdown_item' onClick={async () => {
                setLabelsDropdown(false);
                await company_controller.add_device_label(selectedDevice.device_id, label);
                await device_controller.get_device_list(() => { device_controller.select_device(selectedDevice.device_id) });
              }}>
                {label}
              </p>
            })}
        </div>
        }
      </div>

      <div className={css(deviceInfoPanelStyles.subsection_text)}><span style={{ fontWeight: 500 }}>{t("Notes:")}</span> </div>
      <div style={{ width: '100%', paddingRight: 7 }}>
        <textarea id='commentTextAreaBlockInfo' className={css(deviceInfoPanelStyles.comments)} value={commentText} onChange={(event) => { setCommentChanged(!(event.target.value === selectedDevice.comments)); setCommentText(event.target.value); }} ref={text_area}></textarea>
      </div>
      {commentChanges ? <button className={css(button_styles.main_button)} style={{ padding: '0.3rem 0.4rem', margin: '0.3rem 0', minWidth: '5rem' }}
        onClick={() => {
          device_controller.change_device_comments(selectedDevice.device_id, commentText);
          setCommentChanged(false);
        }}>
        {t("Save")}
      </button> : <></>}
    </section>

    <section>
      <h2 className={css(deviceInfoPanelStyles.section_heading)}>{t("Readings")}</h2>
      <p className={css(deviceInfoPanelStyles.subsection_text)}> <span style={{ fontWeight: 500 }}>{t("Max Height (mm):")}</span> {selectedDevice.backend_max_height_mm}</p>
      <p className={css(deviceInfoPanelStyles.subsection_text)}> <span style={{ fontWeight: 500 }}>{t("Fill Percentage:")}</span> {selectedDevice.current_level_percentage}%</p>

      <div>
        <p className={css(deviceInfoPanelStyles.subsection_text)}>
          <span style={{ fontWeight: 500 }}>{t("Warning Threshold: ")}</span>
          <input
            className={css(deviceInfoPanelStyles.warning_level_input)}
            value={warningLevel ? warningLevel : ""}
            onChange={(event) => {
              setWarningThresholdError('');
              if (!/^\d+$/.test(event.target.value)) {
                setWarningThresholdError(t('Please provide a valid number!'));
              }
              else if (Number(event.target.value) > 90 || (Number(event.target.value) < 10)) {
                setWarningThresholdError(t('Not in the allowable range of 10%-90%!'));
              }
              if (event.target.value.length <= 2) {
                setWarningLevel(Number(event.target.value));
                setWarningLevelChanged(true);
              }
            }}
          />%
        </p>
        <p className={css(deviceInfoPanelStyles.error_text)}>{warningThresholdError}</p>
        <p className={css(deviceInfoPanelStyles.disclaimer)}>{t('Level at which alerts are sent to subscribed users and High Warning level frequencies engage')}</p>
        <p className={css(deviceInfoPanelStyles.subsection_text)}>
          <span style={{ fontWeight: 500 }}>{t("Modes:")}</span>
        </p>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
        }}>
          {companyLowPowerSettings && companyLowPowerSettings.low_power_on && (
            <div className={css(label_styles.label)} style={{ backgroundColor: (selectedDevice.battery_percentage < companyLowPowerSettings.low_power_battery_threshold) ? '#9492D4' : 'transparent' }}>
              <p style={{ margin: 0, color: (selectedDevice.battery_percentage < companyLowPowerSettings.low_power_battery_threshold) ? '#FFFFFF' : '#000000' }}>{t("Low Power")}</p>
            </div>
          )}

          {companyWeathermodeSettings && companyWeathermodeSettings.weathermode_on && (
            <div className={css(label_styles.label)} style={{ backgroundColor: selectedDevice.device_weathermode_on ? '#9492D4' : 'transparent' }}>
              <p style={{ margin: 0, color: selectedDevice.device_weathermode_on ? '#FFFFFF' : '#000000' }}>{t("Weather Mode")}</p>
            </div>
          )}

          {companyROCSettings && companyROCSettings.roc_mode_on && (
            <div className={css(label_styles.label)} style={{ backgroundColor: selectedDevice.labels.includes('High ROC') ? '#9492D4' : 'transparent' }}>
              <p style={{ margin: 0, color: selectedDevice.labels.includes('High ROC') ? '#FFFFFF' : '#000000' }}>{t("High ROC")}</p>
            </div>
          )}

          <div className={css(label_styles.label)} style={{ backgroundColor: selectedDevice.labels.includes('High Level') ? '#9492D4' : 'transparent' }}>
            <p style={{ margin: 0, color: selectedDevice.labels.includes('High Level') ? '#FFFFFF' : '#000000' }}>
              {selectedDevice.labels.includes('High Level') ? t('High Level') : t('Normal Level')}
            </p>
          </div>
        </div>
      </div>
      {warningLevelChanged ? <button className={!warningThresholdError ? css(button_styles.main_button) : css(button_styles.main_button_disabled)}
        style={{ padding: '0.3rem 0.4rem', margin: '0.3rem 0' }}
        onClick={() => {
          if (warningLevel && !warningThresholdError) {
            device_controller.change_device_warning_level_percentage(selectedDevice.device_id, Number(warningLevel));
            setWarningLevelChanged(false);
          }
        }}>
        {t("Save")}
      </button> : <></>}

      <div className={css(deviceInfoPanelStyles.periodButtonRow)}>
        {[['day', t('Day')], ['week', t('Week')], ['month', t('Month')], ['year', t('Year')], ['all', t('All')]].map(([key, label]) => {
          if (periodType === key && key !== 'all') {
            return <div className={css(deviceInfoPanelStyles.periodButtonSelected)}>
                <button className={css(deviceInfoPanelStyles.arrowButton)} onClick={() => decreasePeriod(key as 'day' | 'week' | 'month' | 'year' | 'all')}>
                  {'<'}
                </button>
                {label}
                <button className={css(deviceInfoPanelStyles.arrowButton)} onClick={() => increasePeriod(key as 'day' | 'week' | 'month' | 'year' | 'all')}>
                  {'>'}
                </button>
              </div>
          } else if (periodType === key && key === 'all') {
            return <button className={css(deviceInfoPanelStyles.periodButtonSelected)} onClick={() => changePeriodType(key as 'day' | 'week' | 'month' | 'year' | 'all')}>
                {label}
              </button>
          } else {
            return <button className={css(deviceInfoPanelStyles.periodButton)} onClick={() => changePeriodType(key as 'day' | 'week' | 'month' | 'year' | 'all')}>
                {label}
              </button>
          }
        })}
      </div>
	  <div style={{display: 'flex'}}>
		<MHMDatePicker
			value={periodStartDate.toDate()}
			onChange={(date) => {
				setPeriodStartDate(dayjs(date));
				setPeriodType('custom');
				device_controller.get_device_data(selectedDevice.device_id, dayjs(date).unix(), periodEndDate.unix());
			}}
			error={false}
			shouldDisableDate={(date) => {
				return dayjs(date) < dateRange.start || dayjs(date) > dateRange.end || dayjs(date) > periodEndDate; 
			}}
		/>
		<MHMDatePicker
			value={periodEndDate.toDate()}
			onChange={(date) => {
				setPeriodEndDate(dayjs(date));
				setPeriodType('custom');
				device_controller.get_device_data(selectedDevice.device_id, periodStartDate.unix(), dayjs(date).unix());
			}}
			error={false}
			shouldDisableDate={(date) => {
				return dayjs(date) < dateRange.start || dayjs(date) > dateRange.end || dayjs(date) < periodStartDate; 
			}}
		/>
	  </div>
      <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: '100%'}}>
	    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', textAlign: 'center', width: '100%'}}>
          <Checkbox checked={show_water_level} icon={<img src={CheckboxEmpty} style={{width: 50}}/>} checkedIcon={<img src={CheckboxFull} style={{color: "#000099", width: 50}}/>} sx={{color: '#000099'}} onChange={(event) => {setShowWaterLevel(event.target.checked)}} />
		  <p className={css(deviceInfoPanelStyles.subsection_text)} style={{margin: 0, fontWeight: 500, color: 'rgb(0,0,153)'}}>{t("Water Level (mm)")}</p>
		</div>
	    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', textAlign: 'center', width: '100%'}}>
          <Checkbox checked={show_rainfall} icon={<img src={CheckboxEmpty} style={{width: 50}}/>} checkedIcon={<img src={CheckboxFull} style={{color: "#000099", width: 50}}/>} sx={{color: '#000099'}} onChange={(event) => {setShowRainfall(event.target.checked)}} />
		  <p className={css(deviceInfoPanelStyles.subsection_text)} style={{margin: 0, fontWeight: 500, color: 'rgb(0,153,0)'}}>{t("Rainfall Level (mm)")}</p>
		</div>
      </div>
	  <DeviceGraph
	  	data={selectedDeviceData}
		show_rainfall={show_rainfall}
		show_water_level={show_water_level}
		selectedDevice={selectedDevice}
	  />

	  <button className={css(button_styles.main_button)} style={{ padding: '0.5rem 1rem', margin: '0.3rem 0' }} onClick={() => {setShowDownload(true)}}>
		{t("Download Data")}
	  </button>
	  <DownloadCSVModal open={show_download} setOpen={(open) => {setShowDownload(open)}} device_id={selectedDevice.device_id} initial_start_date={dateRange.start.toDate()} initial_end_date={dateRange.end.toDate()} min_start_date={dateRange.start.toDate()} max_end_date={dateRange.end.toDate()} show_rainfall={show_rainfall} show_water_level={show_water_level}/>
    </section>

    <section>
      <h2 className={css(deviceInfoPanelStyles.section_heading)}>{t("Transmissions")}</h2>
      <p className={css(deviceInfoPanelStyles.disclaimer)}>{t("Missed transmissions and next transmission are calculated based on defined normal operation settings.")}</p>
      <p className={css(deviceInfoPanelStyles.subsection_text)}>
        <span style={{ fontWeight: 500 }}>{t("Sim ID: ")}</span>
        {selectedDevice.sim_id.replace(/["',]/g, "")}
      </p>
      <p className={css(deviceInfoPanelStyles.subsection_text)}> <span style={{ fontWeight: 500 }}>{t("Modem Mode:")}</span> {selectedDevice.modem_mode}</p>
      <p className={css(deviceInfoPanelStyles.subsection_text)}> <span style={{ fontWeight: 500 }}>{t("Last Transmission:")}</span> {unix_timestamp_to_date_time(selectedDevice.last_transmission_time, true, true, false)}</p>
      <p className={css(deviceInfoPanelStyles.subsection_text)}> <span style={{ fontWeight: 500 }}>{t("Next Transmission:")}</span> {unix_timestamp_to_date_time(selectedDevice.expected_next_transmission, true, true, false)}</p>
      <p className={css(deviceInfoPanelStyles.subsection_text)}> <span style={{ fontWeight: 500 }}>{t("Transmissions Missed (current session):")}</span> {selectedDevice.session_missed_transmissions.length}</p>
      <p className={css(deviceInfoPanelStyles.subsection_text)}> <span style={{ fontWeight: 500 }}>{t("Transmissions Missed (lifetime):")}</span> {selectedDevice.all_missed_transmissions.length}</p>
      <p className={css(deviceInfoPanelStyles.subsection_text)}>
    <span style={{ fontWeight: 500 }}>{t("Installation Signal Strength: ")}</span> 
    <span style={{ fontWeight: 500, color: `${get_signal_strength_value(selectedDevice?.signal_strength)[2]}`}}>
      {get_signal_strength_value(selectedDevice?.signal_strength)[1]}% ({get_signal_strength_value(selectedDevice?.signal_strength)[0]})
    </span>
</p>
    </section>

    <section>
      <h2 className={css(deviceInfoPanelStyles.section_heading)}>{t("Installation Info")}</h2>
      <p className={css(deviceInfoPanelStyles.subsection_text)}> <span style={{ fontWeight: 500 }}>{t("Installation Date:")}</span> {dateStr}</p>
      <p className={css(deviceInfoPanelStyles.subsection_text)}> <span style={{ fontWeight: 500 }}>{t("Setup Time (hh:mm:ss):")}</span> 0{Math.floor(selectedDevice.setup_time_s / 3600)}:{Math.floor(selectedDevice.setup_time_s / 60).toString().length < 2 ? `0${Math.floor(selectedDevice.setup_time_s / 60)}` : Math.floor(selectedDevice.setup_time_s / 60)}:{((selectedDevice.setup_time_s % 60).toString().length < 2) ? (`0${(selectedDevice.setup_time_s % 60)}`) : selectedDevice.setup_time_s % 60}</p>

      <div className={css(deviceInfoPanelStyles.subsection_text)}><span style={{ fontWeight: 500 }}>{t("Installation Notes:")}</span> </div>
      <div style={{ width: '100%', paddingRight: 7 }}>
        <textarea id='commentTextAreaBlockInfo' disabled className={css(deviceInfoPanelStyles.comments)} value={selectedDevice.installation_comment} onChange={(event) => { setCommentChanged(!(event.target.value === selectedDevice.comments)); setCommentText(event.target.value); }} ref={text_area}></textarea>
      </div>

      {selectedDevice.images.length > 0 && <p className={css(deviceInfoPanelStyles.subsection_text)}><span style={{ fontWeight: 500 }}>{t("Installation Photos:")}</span></p>}
      <DeviceImagesList/>
    </section>

    {accountState?.name === 'Demo' &&
      <section>
        <button className={css(button_styles.main_button)} style={{ padding: '0.5rem 1rem', margin: '0.3rem 0' }} onClick={() => {
          device_controller.hide_device(selectedDevice.device_id);
        }}>
          {t("Hide Device")}
        </button>
      </section> 
    }
  </article>
})

const DeviceImagesList = (_: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
  const selectedDevice = useRecoilValue(selectedDeviceStateAtom);
  const [currentImage, setCurrentImage] = useState('');

  useEffect(() => {
    setCurrentImage(image_url + selectedDevice?.device_id.toString() + '/' + selectedDevice?.images[0])
  }, [selectedDevice])

  if (selectedDevice === null) {
    return <></>
  }

  return <><div className={css(deviceInfoPanelStyles.gallery)}>
    {selectedDevice.images?.map(image => {
      return <>
        <img className="installation-photo" alt="installation"
          key={image_url + selectedDevice.device_id.toString() + '/' + image}
          src={image_url + selectedDevice.device_id.toString() + '/' + image}
          onClick={(e) => { setCurrentImage((e.target as HTMLInputElement).src) }} />
      </>
    })
    }
  </div>
    <div>
      {selectedDevice.images.length > 0 &&
        <img alt="selected installation" style={{
          marginLeft: 50,
          maxWidth: '70%',
          marginTop: 10,
          clear: 'both',
          borderRadius: 5
        }} key={image_url + selectedDevice.device_id.toString() + '/' + currentImage}
          src={currentImage} />
      }
    </div>
  </>

};
