import React from 'react';
import { memo, useState, useEffect } from "react";
import { useRecoilValue } from "recoil";
import { setRecoil } from "recoil-nexus";
import { accountListStateAtom, modifyAccountModalAtom, newAccountModalAtom, selectedAccountStateAtom } from "../../../models/atoms/account_atoms";
import { account_controller } from "../../../controllers/account_controller";
import { css } from 'aphrodite';
import '../../../App.css';
//  @ts-ignore 
import sort_icon from "../../../assets/sort-icon.svg";
/* eslint-disable no-useless-escape */
import { accountListComponentStyles } from "../../styles/accounts_page_styles";
import { button_styles, label_styles, table_styles } from "../../styles/reusable_styles";
import { useTranslation } from '../../../contexts/TranslationContext';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { TableSortLabel, Typography } from '@mui/material';


export const AccountListComponent = memo((_: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
	const {t} = useTranslation();
		const accountList = useRecoilValue(accountListStateAtom);
		const selectedAccount = useRecoilValue(selectedAccountStateAtom)
		const [searchText, setSearchText] = useState("");
		const [displayedAccounts, setDisplayedAccounts] = useState(accountList);
		const [sortDevicesBy, setSortDevicesBy] = useState('first_name');
		const [sortDirection, setSortDirection] = useState<Order>('asc');

		// every time accountList changes we want to sort them by first name
		useEffect(() => {
		setDisplayedAccounts({...accountList, accounts: accountList.accounts.slice().sort((account_lower, account_upper) => {
			return account_lower.first_name < account_upper.first_name ? -1 : 1;
		})})
		}, [accountList]);
		

		const search_accounts = (searchText : string) => {
			if (!searchText.replace(/\s/g, '').length || !searchText) {
				setDisplayedAccounts({...accountList, accounts: accountList.accounts.slice().sort((account_lower, account_upper) => {
					return account_lower.first_name < account_upper.first_name ? -1 : 1;
				})})
			} else {
				try {
					setDisplayedAccounts({...accountList, accounts: accountList.accounts.filter(account => {
						if (account.first_name.toLowerCase().match(`/${searchText.toLowerCase()}`) 
							|| account.last_name.toLowerCase().match(`/${searchText.toLowerCase()}/g`) 
							|| `${account.first_name.toLowerCase()} ${account.last_name.toLowerCase()}`.match(`${searchText.toLowerCase()}`) 
							|| account.email.toLowerCase().match(`${searchText.toLowerCase()}`)) {
							return account;
						}
						return false;
					})})
				} catch (error) {
					console.log(error);
				}
			}
		}
	
		const handleSearchChange: React.ChangeEventHandler<HTMLInputElement> = (event : React.ChangeEvent<HTMLInputElement>) => {
			setSearchText(event.target.value);
			search_accounts(event.target.value);
		};
	
		if (accountList === undefined) {
			return <></>;
		}

		
	function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
		if (b[orderBy] < a[orderBy]) {
			return -1;
		}
		if (b[orderBy] > a[orderBy]) {
			return 1;
		}
		return 0;
	}
  
	type Order = 'asc' | 'desc';
  
	function getComparator<Key extends keyof any>(
		order: Order,
		orderBy: Key,
	): (a: any, b: any) => number {
		return order === 'desc'
			? (a, b) => descendingComparator(a, b, orderBy)
			: (a, b) => -descendingComparator(a, b, orderBy);
	}

	function updateOrder(orderBy: string) {
		if (sortDevicesBy !== orderBy) {
			setSortDevicesBy(orderBy);
			setSortDirection('asc');
		} else {
			setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
		}
	}

	const headers = [
		{label: 'First Name', key: 'first_name'},
		{label: 'Last Name', key: 'last_name'},
		{label: 'Email', key: 'email'},
		{label: 'Phone Number', key: 'phone_number'},
		{label: 'Permissions', key: 'permissions'}
	]

	return <div className={css(accountListComponentStyles.main_container)}>
		{/* <h1 className={css(accountListComponentStyles.accounts_title)}>{t('Accounts')}</h1> */}
		<div className={css(accountListComponentStyles.search_add_button_container)}>
			<input className={css(accountListComponentStyles.search)} autoComplete='off' type="text" value={searchText} onChange={handleSearchChange} placeholder={t("Search Accounts")}/>
			<button className={css(accountListComponentStyles.search_button)} onClick={() => { setRecoil(newAccountModalAtom, true); }}>{t('Add New Account')}</button>
		</div>
			
		<div className={css(accountListComponentStyles.account_list_card)}>
			<TableContainer>
				<Table stickyHeader>
				<TableHead>
					<TableRow>
					{headers.map(header => (
						<TableCell key={header.label} align='center' onClick={() => updateOrder(header.key)}>
							<TableSortLabel direction={sortDevicesBy === header.key ? sortDirection : 'asc'} hideSortIcon={true}>
								<Typography sx={{fontWeight: 600, fontFamily: '"Ubuntu", sans-serif'}}>{t(header.label)}</Typography>
							</TableSortLabel>
						</TableCell>
					))}
					</TableRow>
				</TableHead>
				<TableBody>
					{displayedAccounts.accounts.slice().sort(getComparator(sortDirection, sortDevicesBy)).map((account) => {
					return <TableRow key={account.id} onClick={() => account_controller.select_account(account.id, () => { setRecoil(modifyAccountModalAtom, true) })}>
						<TableCell align='center' className={css(table_styles.table_cell)}><Typography sx={{fontWeight: 600, fontFamily: '"Ubuntu", sans-serif'}}>{account.first_name}</Typography></TableCell>
						<TableCell align='center' className={css(table_styles.table_cell)}><Typography sx={{fontWeight: 600, fontFamily: '"Ubuntu", sans-serif'}}>{account.last_name}</Typography></TableCell>
						<TableCell align='center' className={css(table_styles.table_cell)}><Typography sx={{fontWeight: 600, fontFamily: '"Ubuntu", sans-serif'}}>{account.email}</Typography></TableCell>
						<TableCell align='center' className={css(table_styles.table_cell)}><Typography sx={{fontWeight: 600, fontFamily: '"Ubuntu", sans-serif'}}>{account.phone_number}</Typography></TableCell>
						<TableCell align='center' className={css(table_styles.table_cell)}>
							<div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center'}}>
								{account.permissions.manage_accounts && <p className={css(label_styles.label)}>{t('Admin')}</p>}
								{account.permissions.register_devices && <p className={css(label_styles.label)}>{t('Install')}</p>}
								{account.permissions.view_devices && <p className={css(label_styles.label)}>{t('View')}</p>}
							</div>
						</TableCell>
					</TableRow>
					})}
				</TableBody>
				</Table>
			</TableContainer>
		</div>
	</div>
})