import { mapStateAtom } from '../models/atoms/ui_atoms';
import { authStateAtom } from '../models/atoms/auth_atoms';
import { deviceListStateAtom, selectedDeviceStateAtom, filterStateAtom, requestIDAtom, dateRangeAtom, selectedDeviceDataAtom } from '../models/atoms/device_atoms';
import { getRecoil, setRecoil } from "recoil-nexus";
import { deviceListInterface, deviceInterface, deviceDataInterface } from '../models/interfaces/device_interfaces';
import dayjs from 'dayjs';


const device_link = process.env.REACT_APP_DEVICE_API_URL;

export const device_controller = {
get_device_list: async function (callback = () => { }) {
	try {
	const filter = getRecoil(filterStateAtom);

	// Ensure filter and filter.labels are defined, this was breaking the test
	if (!filter || !filter.labels) {
		console.error('Filter or filter.labels is undefined');
		return;
	}

	const params = new URLSearchParams({
		pinned: filter.pinned?.toString() || '',
	});

	filter.labels.forEach(label => {
		params.append('labels', label);
	});

	const obj = {
		link: `${device_link}/devices?${params.toString()}`,
		object: {
		method: 'GET',
		headers: {
			'Accept': 'application/json',
			'Authorization': `Bearer ${getRecoil(authStateAtom).token}`,
		}
		}
	};

	const response = await fetch(obj.link, obj.object);

	if (!response.ok) {
		console.error('Failed to fetch device list:', response.status, response.statusText);
		return;
	}

	const deviceList = await response.json() as deviceListInterface;
	setRecoil(deviceListStateAtom, deviceList);
	callback();
	} catch (error) {
	console.error('Error fetching device list:', error);
	}
},

select_device: async function (device_id: string) {
	var obj = {
	link: device_link + '/device?' + new URLSearchParams({
		device_id: device_id,
	}),
	object: {
		method: 'GET',
		headers: {
		'Accept': 'application/json',
		'Authorization': 'Bearer ' + getRecoil(authStateAtom).token,
		}
	}
	};
	await fetch(obj.link, obj.object)
	.then(async response => {
		const data = await response.json() as deviceInterface;
		// console.log('Response data:',data);
		return data;
	})
	.then(device => {
		setRecoil(selectedDeviceStateAtom, device);
		setRecoil(dateRangeAtom, {start: dayjs((device.first_data ?? 0) * 1000).startOf('day'), end: dayjs((device.last_data ?? 0) * 1000).endOf('day')});
		console.log('last_data: ', device.last_data);
		this.get_device_data(device_id, new Date((device.last_data ?? 0) * 1000).setUTCHours(0, 0, 0) / 1000, new Date((device.last_data ?? 0) * 1000).setUTCHours(23, 59, 59) / 1000);
		return device;
	})
	.then(data => {
		setRecoil(mapStateAtom,
		{
			requested_centre: [data.longitude, data.latitude] as [number, number],
			required_update: true,
			requested_zoom: 12,
		})
	});
},

get_device_data: async function (device_id: string, measurement_period_start_date: number, measurement_period_end_date: number) {
	const request_id = getRecoil(requestIDAtom) + 1;
	setRecoil(requestIDAtom, request_id);
	setRecoil(selectedDeviceDataAtom, null);
	console.log('request_id: ', request_id, 'measurement_period_start_date: ', measurement_period_start_date, 'measurement_period_end_date: ', measurement_period_end_date);
	var nextIndex : number | undefined = 0;
	do {
		var link: string = device_link + '/device/data-range?' + new URLSearchParams({
			device_id: device_id,
			measurement_period_start_date: measurement_period_start_date.toString(),
			measurement_period_end_date: measurement_period_end_date.toString(),
			index: nextIndex.toString(),
			limit: '1000',
			request_id: request_id.toString(),
		});
		var obj = {
			link,
			object: {
			method: 'GET',
			headers: {
				'Accept': 'application/json',
				'Authorization': 'Bearer ' + getRecoil(authStateAtom).token,
			}
			}
		};
		const device: deviceDataInterface = (await (await fetch(obj.link, obj.object)).json());
		console.log('device', device)
		const selectedDevice = getRecoil(selectedDeviceStateAtom);
		const current_request_id = getRecoil(requestIDAtom);
		if (!selectedDevice || device.device_id !== selectedDevice.device_id || current_request_id !== device.request_id) {
			return;
		}
		nextIndex = device.next_index;
		console.log(device);
		setRecoil(selectedDeviceDataAtom, (device_data: deviceDataInterface | null) => {
			if (!device_data) {
				return device;
			}
			return {
				...device_data,
				times: [...device_data.times, ...device.times],
				water_levels: [...device_data.water_levels, ...device.water_levels],
				rainfall_levels: [...device_data.rainfall_levels, ...device.rainfall_levels],
			};
		});
		console.log(nextIndex);
	} while (nextIndex);
},

downloadCSV: async function ( device_id: string, start_date: number, end_date: number, show_rainfall: boolean, show_water_level: boolean, file_type: string) {
	var obj = {
	link: device_link + '/device/file?' + new URLSearchParams({
		device_id: device_id,
		measurement_period_start_date: (start_date / 1000).toString(),
		measurement_period_end_date: (end_date / 1000).toString(),
		show_rainfall_level: show_rainfall.toString(),
		show_water_level: show_water_level.toString(),
		file_type: file_type,
	}),
	object: {
		method: 'GET',
		headers: {
		'Accept': 'application/json',
		'Authorization': 'Bearer ' + getRecoil(authStateAtom).token,
		}
	}
	};
	var filename: string;
	await fetch(obj.link, obj.object).then(res => {
		console.log(res)
		const disposition = res.headers.get('Content-Disposition');
		if (!disposition) {
			return;
		}
		filename = disposition.split(/;(.+)/)[1].split(/=(.+)/)[1];
		if (filename.toLowerCase().startsWith("utf-8''"))
			filename = decodeURIComponent(filename.replace("utf-8''", ''));
		else
			filename = filename.replace(/['"]/g, '');
		return res.blob();
	}).then(blob => {
		if (!blob) {
			return;
		}
		var url = window.URL.createObjectURL(blob);
		var a = document.createElement('a');
		a.href = url;
		a.download = filename;
		document.body.appendChild(a);
		a.click();
		a.remove();
	});
},

hide_device: function (device_id: string, callback = () => {}) {
	var obj = {
	link: device_link + '/device/hide?' + new URLSearchParams({
		device_id: device_id,
	}),
	object: {
		method: 'GET',
		headers: {
		'Accept': 'application/json',
		'Authorization': 'Bearer ' + getRecoil(authStateAtom).token,
		}
	}
	};
	fetch(obj.link, obj.object)
	.then(async response => await response.json() as deviceInterface)
	.then(device => {
		setRecoil(selectedDeviceStateAtom, null);
		this.get_device_list(callback)
	});
},

toggle_device_pin: function (device_id: string, callback = () => { }) {
	var obj = {
	link: device_link + '/device?' + new URLSearchParams({
		device_id: device_id,
		measurement_period_type: 'day',
		measurement_period_start_date: '0',
	}),
	object: {
		method: 'GET',
		headers: {
		'Accept': 'application/json',
		'Authorization': 'Bearer ' + getRecoil(authStateAtom).token,
		}
	}
	};
	fetch(obj.link, obj.object)
	.then(async response => await response.json() as deviceInterface)
	.then(device => {
		device.pinned = !device.pinned;
		var obj = {
		link: device_link + '/device',
		object: {
			method: 'PUT',
			headers: {
			'Accept': 'application/json',
			'Authorization': 'Bearer ' + getRecoil(authStateAtom).token,
			'Content-type': 'application/json'
			},
			body: JSON.stringify(
			device
			)
		}
		};
		fetch(obj.link, obj.object).then(() => { this.get_device_list(); callback(); this.select_device(device_id); });
	});
},

change_device_comments: function (device_id: string, comments: string, callback = () => { }) {
	var obj = {
	link: device_link + '/device?' + new URLSearchParams({
		device_id: device_id,
		measurement_period_type: 'day',
		measurement_period_start_date: '0',
	}),
	object: {
		method: 'GET',
		headers: {
		'Accept': 'application/json',
		'Authorization': 'Bearer ' + getRecoil(authStateAtom).token,
		}
	}
	};
	fetch(obj.link, obj.object)
	.then(async response => await response.json() as deviceInterface)
	.then(device => {
		device.comments = comments;
		var obj = {
		link: device_link + '/device',
		object: {
			method: 'PUT',
			headers: {
			'Accept': 'application/json',
			'Authorization': 'Bearer ' + getRecoil(authStateAtom).token,
			'Content-type': 'application/json'
			},
			body: JSON.stringify(
			device
			)
		}
		};
		fetch(obj.link, obj.object).then(() => { this.get_device_list(); callback(); this.select_device(device_id); });
	});
},

change_device_warning_level_percentage: function (device_id: string, warning_level_percentage: number, callback = () => { }) {
	var obj = {
	link: device_link + '/device?' + new URLSearchParams({
		device_id: device_id,
		measurement_period_type: 'none',
		measurement_period_start_date: '0',
	}),
	object: {
		method: 'GET',
		headers: {
		'Accept': 'application/json',
		'Authorization': 'Bearer ' + getRecoil(authStateAtom).token,
		}
	}
	};

	fetch(obj.link, obj.object)
	.then(async response => await response.json() as deviceInterface)
	.then(device => {
		device.warning_level_percentage = warning_level_percentage;
		var obj = {
		link: device_link + '/device',
		object: {
			method: 'PUT',
			headers: {
			'Accept': 'application/json',
			'Authorization': 'Bearer ' + getRecoil(authStateAtom).token,
			'Content-type': 'application/json'
			},
			body: JSON.stringify(
			device
			)
		}
		};
		fetch(obj.link, obj.object).then(() => { this.get_device_list(); callback(); this.select_device(device_id); });
	});
},
}