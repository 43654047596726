/* eslint-disable no-useless-escape */
import React from 'react';
import { DeviceInfoPanel } from "../components/device/DeviceInfo";
import '../../App.css';
import "bootstrap-icons/font/bootstrap-icons.css";
import '../styles/shared_styles.css';
import { mapStateAtom } from '../../models/atoms/ui_atoms';
import { panelSizesAtom } from "../../models/atoms/ui_atoms";
import { getRecoil, setRecoil } from "recoil-nexus";

import { useState, memo, useEffect, useLayoutEffect } from "react";
import SplitPane from "react-split-pane";
import Pane from "react-split-pane";
import { MapComponent } from "../components/MapComponent";
import { sizes } from '../styles/style_variables';
import { DeviceListComponent } from "../components/device/DeviceList";
import { device_controller } from '../../controllers/device_controller';
import { company_controller } from '../../controllers/company_controller';
import { selectedDeviceStateAtom } from '../../models/atoms/device_atoms';
import { useRecoilValue } from 'recoil';

export const DeviceMetricsPage = (_: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
  useEffect(() => {
    setRecoil(mapStateAtom, {
      ...getRecoil(mapStateAtom),
      required_update: true,
      requested_zoom: 5,
    })
  });

  useEffect(() => {
    device_controller.get_device_list();
    company_controller._get_labels();
    const selectedDevice = getRecoil(selectedDeviceStateAtom);
    if (selectedDevice) {
      device_controller.select_device(selectedDevice.device_id)
    }
  }, [])
  return <VerticalSplit />
};


const VerticalSplit = memo((props: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
  const [vw, setVW] = useState(Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0));
  const [split, setSplit] = useState(0.6);

  useLayoutEffect(() => {
	function updateSize() {
		setVW(Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0))
	}
	window.addEventListener('resize', updateSize);
	updateSize();
	return () => window.removeEventListener('resize', updateSize);
  }, [])

  const onChange = (size: [string, string]) => {
    setSplit(parseInt(size[0].replace(/px/, "")) / vw);
  };

  const leftWidth = Math.floor(split * vw) + 'px';
  const rightWidth = Math.floor((1 - split) * vw) + 'px';
  // Store the panel size in a Recoil Atom
  setRecoil(panelSizesAtom, { ...getRecoil(panelSizesAtom), vLeft: leftWidth, vRight: rightWidth });
  // Ignores required due to bugs in split pane typescript definitions
  return (
    <>
      {/* @ts-ignore */}
      <SplitPane
        split="vertical"
        // @ts-ignore
        onChange={(size: [string, string]) => {
          onChange(size);
        }}
      >
        {/* @ts-ignore */}
        <Pane initialSize={leftWidth} minSize="50%" maxSize="90%">
            <HorizontalSplit style={{width: leftWidth}}/>
        </Pane>
        {/* @ts-ignore */}
        <Pane minSize="10%" maxSize="90%">
			<div style={{height: '100%', width: rightWidth, padding: '1rem'}}>
            	<DeviceInfoPanel />
			</div>
        </Pane>
      </SplitPane>
    </>
  );
});


const HorizontalSplit = memo((props: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
  const [vh, setVH] = useState(Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0) - 2 * sizes.header);
  const [split, setSplit] = useState(0.5);

  const onChange = (size: [string, string]): void => {
	setSplit(parseInt(size[0].replace(/px/, "")) / vh);
  };

  useLayoutEffect(() => {
	function updateSize() {
		setVH(Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0) - 2 * sizes.header);
	}
	window.addEventListener('resize', updateSize);
	updateSize();
	return () => window.removeEventListener('resize', updateSize);
  }, [])

  const topHeight = Math.floor(split * vh) + 'px';
  const bottomHeight = Math.floor((1 - split) * vh) + 'px';
  setRecoil(panelSizesAtom, { ...getRecoil(panelSizesAtom), hTop: topHeight, hBottom: bottomHeight });
  return (
    <div>
      {/* @ts-ignore */}
      <SplitPane split="horizontal" onChange={size => onChange(size)}>
        {/* @ts-ignore */}
        <Pane
          initialSize={topHeight}
          minSize={Math.floor(0.1 * vh).toString() + "px"}
          maxSize={Math.floor(0.9 * vh).toString() + "px"}
        >
          <div id="mapID" style={{height: topHeight, width: '100%'}}>
            <MapComponent />
          </div>
        </Pane>
        <div style={{ height: bottomHeight, width: '100%', padding: '1rem' }}>
        	<DeviceListComponent />
        </div>
      </SplitPane>
    </div>
  );
});






