import styled from "@emotion/styled";

import { FormControlLabel, FormControlLabelProps } from "@mui/material";
import Select, { SelectProps } from "@mui/material/Select/Select";
import { useTranslation } from "../../contexts/TranslationContext";
import { ReactNode } from "react";

const StyledSelect = styled(Select)`

`;

export const MHMSelect: React.FC<SelectProps> = (props: SelectProps) => {
	return (
		<StyledSelect
			{...props}
		/>
	);
}

export const MHMLabelledSelect: React.FC<{ selectProps: SelectProps, label: ReactNode, children?: ReactNode }> = (props: { selectProps: SelectProps, label: ReactNode, children?: ReactNode }) => {
	return (
		<FormControlLabel sx={{marginLeft: 0, marginRight: 0}} label={props.label} labelPlacement="start" control={<MHMSelect {...props.selectProps}>{props.children}</MHMSelect>} />
	);
}

