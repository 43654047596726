import React, { useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContentText, DialogActions, Typography } from '@mui/material';
import { account_controller } from '../../controllers/account_controller';
import { useRecoilState } from 'recoil';
import { firstTimeLoginAtom } from '../../models/atoms/account_atoms';
import { useTranslation } from '../../contexts/TranslationContext';
import { MHMDialogContent } from './DialogContent';
import { MHMTextField } from './TextField';
import { MHMLabelledCheckbox } from './Checkbox';
import { MHMTypography } from './Typography';
import { MHMHyperlink } from './Hyperlink';

const PolicyLinks: React.FC = () => {
	return (
		<MHMTypography>Agree to our <MHMHyperlink href="https://termsofuse.manholemetrics.com" target="_blank">Terms of Service</MHMHyperlink> and <MHMHyperlink href="https://privacyandcookies.manholemetrics.com" target="_blank">Privacy Policy</MHMHyperlink></MHMTypography>
	);
}

const FirstLoginModal: React.FC = () => {
	const [firstTimeLogin, setFirstTimeLogin] = useRecoilState(firstTimeLoginAtom);
	const [error, setError] = useState('');
	const { t } = useTranslation();
	const [readManual, setReadManual] = useState(false);
	console.log('firstTimeLogin', firstTimeLogin);

	return (
		<Dialog
			open={firstTimeLogin}
			onClose={() => {}}
			PaperProps={{
				component: 'form',
				onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
					event.preventDefault();
					const formData = new FormData(event.currentTarget);
					const formJson = Object.fromEntries((formData as any).entries());
					const newPassword = formJson.newPassword;
					const oldPassword = formJson.oldPassword;
					const confirmPassword = formJson.confirmPassword;
					try {
						if (newPassword === oldPassword) {
							setError(t('New password cannot be the same as the old password!'));
							return;
						}
						if (newPassword !== confirmPassword) {
							setError(t("Your passwords don't match"));
							return;
						}
						if (newPassword.length < 12 || !(/\d/).test(newPassword) || !(/[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/).test(newPassword)) {
							setError(t("Password must be at least 12 characters and contain at least 1 number and 1 special character!"));
							return;
						} 
						account_controller.change_user_password(newPassword, oldPassword).then(() => {
							setFirstTimeLogin(false);
							localStorage.setItem('firstTimeLogin', 'false');
						});
					} catch (error) {
						console.error('Validation failed:', error);
					}
				}
			}}
		>
			<DialogTitle>{t("Change Password")}</DialogTitle>
			<MHMDialogContent sx={{display: 'flex', flexDirection: 'column'}}>
				<MHMTextField autoFocus required margin="dense" id="oldPassword" name="oldPassword" label={t("Old Password")} type="password" />
				<MHMTextField required margin="dense" id="newPassword" name="newPassword" label={t("New Password")} type="password" />
				<MHMTextField required margin="dense" id="confirmPassword" name="confirmPassword" label={t("Repeat New Password")} type="password" />
				<DialogContentText style={{color: 'red'}}>{error}</DialogContentText>
          		<MHMLabelledCheckbox checkboxProps={{checked: readManual, onChange: (event: any) => setReadManual(event.target.checked)}} label={<PolicyLinks />} />
			</MHMDialogContent>
			<DialogActions>
				<Button type="submit" color="primary" disabled={!readManual}>{t("Confirm")}</Button>
			</DialogActions>
		</Dialog>
	);
};

export default FirstLoginModal;