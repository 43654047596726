import { StyleSheet } from 'aphrodite';
import { button_background_colour, button_font_size, button_font_weight, button_text_colour } from './style_variables';

export const accountsPageStyle = StyleSheet.create({
	form_title: {
		fontSize: '1.2em',
		fontWeight: 400
	},
	main_style: {
		display: 'flex',
		justifyContent: 'center',
		flexDirection: 'column',
		alignItems: 'center'
	},
	row_style: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		width: '90%',
		marginBottom: 10
	},
	create_new_style: {
		backgroundColor: '#277BC0',
		boxShadow: 0,
		borderColor: '#277BC0',
		marginRight: 5,
		width: '15em',
		height: '3em',
		fontWeight: 'bold',
	},
	break_style: {
		flexBasis: '100%',
		height: 0
	},
	account_button_style: {
		height: '3em',
		fontSize: 18,
		width: '33em',
		marginLeft: 0,
		marginRight: 0,
		background: 'rgb(91, 192, 248) !important',
		borderColor: 'transparent',
		marginBottom: 20,
		textAlign: 'left',
		color: 'black',
		fontWeight: 500
	},
	search_style: {
		width: '78%',
		height: '3em',
		paddingRight: '10em',
		marginLeft: '0em',
		marginRight: '2em',
	},
	delete_button_style: {
		backgroundColor: "#c4120c",
		borderColor: "#c4120c",
		marginTop: 15,
		width: '5em',
		fontWeight: 500
	},
	submit_button_style: {
		marginTop: 10,
		width: '5em',
		fontWeight: 500
	},
	cancel_button_style: {
		backgroundColor: "#B4B4B4",
		borderColor: "#B4B4B4",
		width: '100%',
		fontWeight: 500
	},
	confirm_delete_button_style: {
		backgroundColor: "#c4120c",
		borderColor: "#c4120c",
		width: '100%',
		fontWeight: 500
	},
	delete_model_style: {
		width: '300px',
		height: '300px',
		position: 'absolute',
		left: ' 50%',
		top: '50%',
		marginLeft: '-150px',
		marginTop: '-150px',
		textAlign: 'center'
	},
	delete_model_header: {
		display: 'flex',
		justifyContent: 'center',
		flexDirection: 'row'
	},
	link: {
		color: "#000099",
		textDecoration: 'underline',
	},
});

export const accountListComponentStyles = StyleSheet.create({
	main_container: {
		alignItems: 'center',
		justifyContent: 'center',
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		height: '100%',
		marginTop: '1rem',
	},
	accounts_title: {
		fontSize: '1.8rem',
		fontWeight: 600
	},
	account_list_card: {
		flex: 1,
		width: '100%',
		paddingLeft: '1rem',
		paddingRight: '1rem',
		borderRadius: 5,
		overflow: 'hidden',
		display: 'flex',
		flexDirection: 'column'
	}, 
	search_add_button_container: {
		display: 'flex',
		width: '100%',
		marginBottom: '1rem',
		flexDirection: 'row',
		justifyContent: 'space-between',
		paddingLeft: '1rem',
		paddingRight: '1rem',
	},
	search: {
		flex: 6,
		height: '3rem',
		backgroundColor: '#ffffff',
		border: '1px solid lightgrey',
		borderRadius: 5,
		color: 'black',
		paddingLeft: '1rem',
	},
	search_button: {
        borderRadius: 5,
        border: 'none',
		height: '3rem',
		flex: 1,
        backgroundColor: button_background_colour,
        fontSize: button_font_size,
        fontWeight: button_font_weight,
        color: button_text_colour,
		marginLeft: '1rem',
    },
})